import type { AppRole } from "./supabase.enum";

export function isTeamMember(roles: AppRole[]) {
  return roles.includes("team_member");
}

export function alreadyOnboarded(teamMember: TeamMember | undefined, roles: AppRole[]) {
  return !!teamMember && !hasOnlyTeamMemberRole(roles) && !!teamMember.manager_id;
}
export function hasOnlyTeamMemberRole(roles: AppRole[]) {
  return roles.length === 1 && roles.includes("team_member");
}
